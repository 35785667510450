import { Fragment } from "react";

import { Dayjs, YyyyMmDd } from "@m7-health/shared-utils";

import { Box, Stack, Typography } from "@mui/material";

import { IUnitBasic } from "~/routes/api/types";

import { IUnitConfig } from "#/features/User/types";
import { INote, IStaffDetails, IStaffShift } from "@/api";
import { ExpandMore } from "@/common/components/ExpandMore/ExpandMore";
import { lightPurple, lines } from "@/common/theming";
import { KeyBy } from "@/common/types";

import { UnitDayCell } from "./unitDayCell";

export const UnitRow = ({
  isCollapsed,
  setCollapsedUnits,
  unit,
  shiftsByDayByUnit,
  staffDetailsByUserId,
  notesByUserId,
  notesFromStaffByUserId,
  firstRange,
}: {
  isCollapsed: boolean;
  setCollapsedUnits: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
  unit: IUnitConfig;
  shiftsByDayByUnit: Record<YyyyMmDd, Record<IUnitBasic["id"], IStaffShift[]>>;
  staffDetailsByUserId: KeyBy<IStaffDetails, "userId">;
  notesByUserId: KeyBy<INote, "userId">;
  notesFromStaffByUserId: KeyBy<INote, "userId">;
  firstRange: {
    from: Dayjs;
    to: Dayjs;
    allDays: Dayjs[];
    formattedDays: YyyyMmDd[];
  };
}) => {
  return (
    <Fragment key={unit.id}>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          marginTop: 1,
          borderBottom: "0px solid black",
        }}
      >
        <Box
          sx={{
            width: "100%",
            background: !isCollapsed ? `${lightPurple}60` : lines,
            borderRadius: "10px 10px 0 0",
          }}
        >
          <Stack
            direction="row"
            sx={{
              alignItems: "center",
              width: "fit-content",
            }}
          >
            <ExpandMore
              direction="right"
              sx={{ padding: 0.5 }}
              expand={!isCollapsed}
              onClick={() =>
                setCollapsedUnits((prev) => ({
                  ...prev,
                  [unit.id]: !prev[unit.id],
                }))
              }
            />
            <Typography sx={{ fontSize: 14 }}>{unit.name}</Typography>
          </Stack>
        </Box>
      </Box>
      {!isCollapsed && (
        <Box className="unit-data-row" gap={0}>
          {firstRange.formattedDays.map((formattedDay) => (
            <UnitDayCell
              key={`${unit.id}-${formattedDay}`}
              unit={unit}
              formattedDay={formattedDay}
              shiftsByDayByUnit={shiftsByDayByUnit}
              staffDetailsByUserId={staffDetailsByUserId}
              notesByUserId={notesByUserId}
              notesFromStaffByUserId={notesFromStaffByUserId}
            />
          ))}
        </Box>
      )}
    </Fragment>
  );
};
